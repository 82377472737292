
import { FormController, validationRules } from '@icepanel/app-form'
import { BackstageAPI, BackstageComponent, BackstageDomain, BackstageEntity, BackstageResource, BackstageSystem } from '@icepanel/platform-api-client'
import * as yaml from 'js-yaml'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { UserModule } from '@/modules/user/store'

import * as analytics from '../helpers/analytics'
import { LandscapeModule } from '../store'

@Component({
  components: {
    Dialog
  },
  name: 'LandscapeBackstageImportDialog'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  get currentOrganizationId () {
    return (this.$params.organizationId || this.currentLandscape?.organizationId)!
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get valid () {
    return !!this.formController.model.backstageEntities
  }

  formController = new FormController({
    initialModel: {
      backstageEntities: null as File | null
    },
    validationRules: {
      backstageEntities: validationRules.exists
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      const text = await model.backstageEntities?.text()

      let backstageEntities: BackstageEntity[] = []
      if (model.backstageEntities?.type === 'application/x-yaml' && text) {
        backstageEntities = yaml.loadAll(text) as any
      } else if (model.backstageEntities?.type === 'application/json' && text) {
        backstageEntities = JSON.parse(text)
      } else {
        throw new Error(`Unknown type ${model.backstageEntities?.type}`)
      }

      const {
        landscape
      } = await this.landscapeModule.landscapeCreate({
        backstageEntities: {
          apis: backstageEntities.filter((o): o is BackstageAPI => o.kind === 'API'),
          components: backstageEntities.filter((o): o is BackstageComponent => o.kind === 'Component'),
          domains: backstageEntities.filter((o): o is BackstageDomain => o.kind === 'Domain'),
          resources: backstageEntities.filter((o): o is BackstageResource => o.kind === 'Resource'),
          systems: backstageEntities.filter((o): o is BackstageSystem => o.kind === 'System')
        },
        name: 'Backstage import',
        organizationId: this.currentOrganizationId
      })

      analytics.landscapeBackstageImport.track(this, {
        landscapeId: [landscape.id],
        organizationId: [landscape.organizationId]
      })
      analytics.landscapeCreate.track(this, {
        landscapeId: [landscape.id],
        landscapeName: landscape.name,
        organizationId: [landscape.organizationId]
      })

      this.$router.push({
        name: 'model-objects',
        params: {
          landscapeId: landscape.id
        }
      })
    }
  }

  open () {
    this.formController.resetModel()
    this.formController.resetStatus()
  }

  opened () {
    analytics.landscapeBackstageImportDialog.track(this, {
      organizationId: [this.currentOrganizationId]
    })
  }
}
