
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import SidebarMenu from '@/modules/navigation/views/sidebar-menu.vue'
import { VersionModule } from '@/modules/version/store'
import * as router from '@/plugins/router'

import { ShareModule } from '../store'

@Component({
  components: {
    SidebarMenu
  },
  name: 'ShareLandscape'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)!
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentShareLink.versionId)!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentVersion.landscapeId)!
  }

  get pageTitle () {
    const sections: string[] = []
    if (this.$route.meta?.title) {
      sections.push(this.$route.meta.title)
    }
    if (this.currentLandscape) {
      sections.push(this.currentLandscape.name)
    }
    return sections
  }

  @Watch('pageTitle')
  onPageTitleChanged (sections: string[]) {
    router.setTitle(sections)
  }

  mounted () {
    if (this.pageTitle) {
      router.setTitle(this.pageTitle)
    }
  }
}
