
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { LandscapeModule } from '@/modules/landscape/store'

import * as analytics from '../helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'LandscapeDeleteDialog'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)

  get landscapeDeleteDialog () {
    return this.$queryValue('landscape_delete_dialog')
  }

  get landscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.landscapeDeleteDialog)!
  }

  get landscapeDeleteStatus () {
    return this.landscapeModule.landscapeDeleteStatus
  }

  async deleteLandscape () {
    const landscapeId = this.landscape.id
    const organizationId = this.landscape.organizationId

    await this.landscapeModule.landscapeDelete({
      landscapeId,
      organizationId
    })

    analytics.landscapeDelete.track(this, {
      landscapeId: [landscapeId],
      organizationId: [organizationId]
    })

    await this.$replaceQuery({
      landscape_delete_dialog: undefined
    })
    await this.$router.push({
      name: 'landscapes',
      params: {
        organizationId
      }
    })
  }

  opened () {
    analytics.landscapeDeleteDialog.track(this, {
      landscapeId: [this.landscape.id],
      organizationId: [this.landscape.organizationId]
    })
  }
}
