
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import DomainDropdown from '@/modules/domain/components/domain-dropdown.vue'

import CollapseButton from '../components/collapse-button.vue'
import NavigationItems from '../components/navigation-items.vue'
import { NavigationModule } from '../store'

@Component({
  components: {
    CollapseButton,
    DomainDropdown,
    NavigationItems
  },
  name: 'LandscapeSidebarMenu'
})
export default class extends Vue {
  navigationModule = getModule(NavigationModule, this.$store)

  collapsedThreshold = 720
  viewWidth = 0

  get fitsExpanded () {
    return this.viewWidth >= this.collapsedThreshold
  }

  get expanded () {
    return this.navigationModule.expanded && this.fitsExpanded
  }

  created () {
    this.resize()
  }

  resize () {
    this.viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  }

  toggleWideSidebar () {
    this.navigationModule.setSidebarExpanded(!this.navigationModule.expanded)
  }
}
