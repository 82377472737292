
import { FormController, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { OrganizationModule } from '@/modules/organization/store'

import * as analytics from '../helpers/analytics'
import { LandscapeModule } from '../store'

@Component({
  components: {
    Dialog
  },
  name: 'LandscapeCopyDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get landscapeCopyDialog () {
    return this.$queryValue('landscape_copy_dialog')
  }

  get landscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.landscapeCopyDialog)
  }

  get targetLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.formController.model.targetLandscapeId)
  }

  get items () {
    return this.landscapeModule.landscapes
      .filter(o => o.id !== this.landscapeCopyDialog)
      .map(o => ({
        id: o.id,
        text: o.name,
        value: o.id
      }))
  }

  formController = new FormController({
    initialModel: {
      targetLandscapeId: '' as string
    },
    validationRules: {
      targetLandscapeId: validationRules.exists
    }
  })

  created () {
    this.formController.submitHandler = async () => {
      const landscape = this.landscape
      if (!landscape) {
        throw new Error('Could not find landscape')
      }

      const targetLandscape = this.targetLandscape
      if (!targetLandscape) {
        throw new Error('Could not find target landscape')
      }

      try {
        await this.landscapeModule.landscapeCopy({
          landscapeId: landscape.id,
          organizationId: landscape.organizationId,
          targetLandscapeId: targetLandscape.id
        })
      } catch (err: any) {
        throw new Error(`${err.body.message}\n` + err.body.errors.join('\n'))
      }

      analytics.landscapeCopy.track(this, {
        landscapeId: [landscape.id, targetLandscape.id],
        landscapeName: targetLandscape.name,
        organizationId: [landscape.organizationId, targetLandscape.organizationId]
      })

      this.alertModule.pushAlert({
        color: 'success',
        message: `Copied from ${landscape.name} to ${targetLandscape.name}`
      })

      this.$router.push({
        name: 'landscape',
        params: {
          landscapeId: this.formController.model.targetLandscapeId
        }
      })
    }
  }

  open () {
    this.formController.resetModel()
    this.formController.resetStatus()
  }

  opened () {
    analytics.landscapeCopyDialog.track(this, {
      landscapeId: this.landscape ? [this.landscape.id] : [],
      organizationId: [this.currentOrganizationId]
    })
  }
}
