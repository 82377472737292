
import { differenceInDays } from 'date-fns'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Menu from '@/components/menu.vue'
import Tabs, { ITab } from '@/components/tabs.vue'
import AccuracyHelpDialog from '@/modules/accuracy/components/help-dialog.vue'
import CommentThreadDialog from '@/modules/comment/components/comment-dialog/thread.vue'
import { DomainModule } from '@/modules/domain/store'
import { EditorModule } from '@/modules/editor/store'
import { ModelModule } from '@/modules/model/store'
import SidebarMenu from '@/modules/navigation/views/sidebar-menu.vue'
import OrganizationOverdueAlert from '@/modules/organization/components/overdue-alert.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { SocketModule } from '@/modules/socket/store'
import { UserModule } from '@/modules/user/store'
import { VersionModule } from '@/modules/version/store'
import * as router from '@/plugins/router'

import BackstageImportDialog from '../components/backstage-import-dialog.vue'
import CopyDialog from '../components/copy-dialog.vue'
import DeleteDialog from '../components/delete-dialog.vue'
import DuplicateDialog from '../components/duplicate-dialog.vue'
import List from '../components/list.vue'
import StructurizrImportDialog from '../components/structurizr-import-dialog.vue'
import { LandscapeModule } from '../store'

const LOCATION_INTERVAL = 10 * 1000

@Component({
  components: {
    AccuracyHelpDialog,
    BackstageImportDialog,
    CommentThreadDialog,
    CopyDialog,
    DeleteDialog,
    DuplicateDialog,
    List,
    Menu,
    OrganizationOverdueAlert,
    SidebarMenu,
    StructurizrImportDialog,
    Tabs
  },
  name: 'Landscape'
})
export default class extends Vue {
  domainModule = getModule(DomainModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  socketModule = getModule(SocketModule, this.$store)
  userModule = getModule(UserModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Ref() readonly menuRef!: Menu

  locationInterval?: number

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentTeamId () {
    return this.$queryValue('team')
  }

  get currentDomainHandleId () {
    return this.$queryValue('domain')
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  get currentDomain () {
    const domains = Object.values(this.domainModule.domains)
    return domains.length === 1 ? domains[0] : domains.find(o => o.handleId === this.currentDomainHandleId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get organizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get landscapePermission () {
    return this.currentVersionId === 'latest' ? this.landscapeModule.landscapePermission(this.currentLandscape) : 'read'
  }

  get organizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get organizationOverdue () {
    return this.currentOrganization?.status === 'past_due' || this.currentOrganization?.status === 'unpaid'
  }

  get showOrganizationTrial () {
    return this.organizationPermission &&
      this.organizationPermission !== 'read' &&
      this.currentOrganization &&
      this.currentOrganization.trialEndsAt &&
      (this.currentOrganization.status === 'trialing' || (this.currentOrganization.plan === 'free' && differenceInDays(new Date(this.currentOrganization.trialEndsAt), new Date()) > -7))
  }

  get domainsLoading () {
    return (
      !this.landscapesListStatus.success ||
      !this.organizationModule.organizationsListStatus.success ||
      (this.landscapeModule.landscapeSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.landscapeModule.landscapeSubscriptionStatus.loadingInfo.reconnect) ||
      (this.domainModule.domainsSubscriptionStatus.successInfo.landscapeId !== this.currentLandscapeId && !this.domainModule.domainsSubscriptionStatus.loadingInfo.reconnect)
    )
  }

  get travelling () {
    return this.versionModule.travelling
  }

  get versionRevertsListStatus () {
    return this.versionModule.versionRevertsListStatus
  }

  get landscapesListStatus () {
    return this.landscapeModule.landscapesListStatus
  }

  get domainsSubscriptionStatus () {
    return this.domainModule.domainsSubscriptionStatus
  }

  get domains () {
    return Object.values(this.domainModule.domains)
  }

  get socketError () {
    return this.socketModule.socketSubscriptionActive ? undefined : (this.socketModule.socketSubscriptionError || this.socketModule.socketError)
  }

  get landscapeDuplicateStatus () {
    return this.landscapeModule.landscapeDuplicateStatus
  }

  get domainsAll () {
    return !this.currentDomainHandleId && this.domains.length > 1
  }

  get tabs () {
    return [
      {
        icon: '$fas-project-diagram',
        id: 'diagrams',
        text: 'Diagrams',
        to: {
          name: 'diagrams',
          params: {
            landscapeId: this.currentLandscapeId || ' ',
            versionId: this.currentVersionId
          },
          query: {
            domain: this.currentDomainHandleId || undefined
          }
        }
      },
      {
        icon: '$fas-sitemap',
        id: 'model-objects',
        text: 'Model objects',
        to: {
          name: 'model-objects',
          params: {
            landscapeId: this.currentLandscapeId || ' ',
            versionId: this.currentVersionId
          },
          query: {
            ...this.$routeName === 'model-objects' ? this.$query : undefined,
            domain: this.currentDomainHandleId || undefined
          }
        }
      },
      this.$feature('dependency-viewer')
        ? {
          icon: '$fas-exchange-alt',
          id: 'dependencies',
          text: 'Dependencies',
          to: {
            name: 'model-dependencies',
            params: {
              landscapeId: this.currentLandscapeId || ' ',
              versionId: this.currentVersionId
            },
            query: {
              ...this.$routeName === 'model-dependencies' ? this.$query : undefined,
              domain: this.currentDomainHandleId || undefined
            }
          }
        } as ITab
        : null,
      {
        icon: '$fas-users',
        id: 'teams',
        text: 'Teams',
        to: {
          name: 'teams',
          params: {
            landscapeId: this.currentLandscapeId || ' ',
            versionId: this.currentVersionId
          },
          query: {
            domain: this.currentDomainHandleId || undefined,
            team: this.currentTeamId || undefined
          }
        }
      },
      {
        icon: '$fas-clock',
        id: 'history',
        text: 'History',
        to: {
          name: 'history',
          params: {
            landscapeId: this.currentLandscapeId || ' ',
            versionId: this.currentVersionId
          },
          query: {
            domain: this.currentDomainHandleId || undefined
          }
        }
      }
    ].filter((o): o is ITab => !!o)
  }

  get pageTitle () {
    const sections: string[] = []
    if (this.$route.meta?.title) {
      sections.push(this.$route.meta.title)
    }
    if (this.currentLandscape) {
      sections.push(this.currentLandscape.name)
    }
    return sections
  }

  get domainsActive () {
    return this.domains.length >= 2 || !!this.domains[0].name
  }

  @Watch('pageTitle')
  onPageTitleChanged (sections: string[]) {
    router.setTitle(sections)
  }

  @Watch('currentOrganizationId')
  onCurrentOrganizationIdChanged (currentOrganizationId?: string) {
    if (currentOrganizationId && this.landscapesListStatus.loadingInfo.organizationId !== currentOrganizationId && this.landscapesListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.landscapeModule.setLandscapes([])
      this.landscapeModule.landscapesList(currentOrganizationId)
    }

    if (currentOrganizationId && this.organizationModule.organizationUsersListStatus.loadingInfo.organizationId !== currentOrganizationId && this.organizationModule.organizationUsersListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.organizationModule.organizationUsersList(currentOrganizationId)
    }
  }

  @Watch('currentLandscapeId')
  onCurrentLandscapeIdChanged (currentLandscapeId?: string) {
    if (!currentLandscapeId && this.currentOrganizationId) {
      this.setCurrentLandscape(this.currentOrganizationId)
    }
  }

  @Watch('currentVersionId')
  onCurrentVersionIdChanged (currentVersionId: string) {
    this.locationUpdate()

    if (currentVersionId !== 'latest' && this.currentLandscapeId && this.versionRevertsListStatus.loadingInfo.landscapeId !== this.currentLandscapeId && this.versionRevertsListStatus.successInfo.landscapeId !== this.currentLandscapeId) {
      this.versionModule.versionRevertsList(this.currentLandscapeId)
    }
  }

  @Watch('landscapesListStatus.success')
  onLandscapeListStatusChanged (success: boolean) {
    if (success && !this.currentLandscapeId && this.currentOrganizationId) {
      this.setCurrentLandscape(this.currentOrganizationId)
    }
  }

  mounted () {
    if (this.pageTitle) {
      router.setTitle(this.pageTitle)
    }

    const currentOrganizationId = this.currentOrganizationId
    if (currentOrganizationId && this.landscapesListStatus.loadingInfo.organizationId !== currentOrganizationId && this.landscapesListStatus.successInfo.organizationId !== currentOrganizationId) {
      this.landscapeModule.setLandscapes([])
      this.landscapeModule.landscapesList(currentOrganizationId)
    } else if (!this.currentLandscapeId && currentOrganizationId) {
      this.setCurrentLandscape(currentOrganizationId)
    }

    if (this.currentVersionId !== 'latest' && this.currentLandscapeId && this.versionRevertsListStatus.loadingInfo.landscapeId !== this.currentLandscapeId && this.versionRevertsListStatus.successInfo.landscapeId !== this.currentLandscapeId) {
      this.versionModule.versionRevertsList(this.currentLandscapeId)
    }

    if (this.currentOrganizationId && this.organizationModule.organizationUsersListStatus.loadingInfo.organizationId !== this.currentOrganizationId && this.organizationModule.organizationUsersListStatus.successInfo.organizationId !== this.currentOrganizationId) {
      this.organizationModule.organizationUsersList(this.currentOrganizationId)
    }

    clearInterval(this.locationInterval)
    this.locationInterval = window.setInterval(this.locationUpdate.bind(this), LOCATION_INTERVAL)
    this.locationUpdate()
  }

  destroyed () {
    clearInterval(this.locationInterval)
  }

  async setCurrentLandscape (currentOrganizationId: string) {
    if (this.landscapeModule.landscapes.length) {
      const defaultOrganizationLandscapeIds = this.userModule.user?.defaultOrganizationLandscapeIds || {}
      const defaultOrganizationLandscape = this.landscapeModule.landscapes.find(o => o.id === defaultOrganizationLandscapeIds[currentOrganizationId])
      const landscapeId = defaultOrganizationLandscape?.id || this.landscapeModule.landscapes[0].id

      await this.$router.replace({
        name: 'landscape',
        params: {
          landscapeId,
          versionId: 'latest'
        },
        query: this.$route.query
      })

      this.locationUpdate()
    }
  }

  newLandscapeAction () {
    this.$router.push({
      name: 'landscape-setup',
      query: {
        organization: this.currentOrganization?.id
      }
    })
  }

  locationUpdate () {
    if (!this.currentLandscapeId || !this.userModule.user || !this.currentOrganization || !this.currentOrganization.userIds.includes(this.userModule.user.id)) {
      return
    }

    this.editorModule.editorLocationUpdate({
      landscapeId: this.currentLandscapeId,
      location: {
        versionId: this.currentVersionId
      }
    })
  }
}
