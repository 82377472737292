
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'

import * as analytics from '../helpers/analytics'
import { LandscapeModule } from '../store'

@Component({
  components: {
    OrganizationUpgradeMenu
  },
  name: 'LandscapeActionsMenu'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  @Prop() readonly landscapeId!: string

  @Ref() readonly menuRef!: any

  get organization () {
    return this.organizationModule.organizations.find(o => o.id === this.landscape.organizationId)!
  }

  get organizationLimits () {
    return this.organizationModule.organizationLimits(this.organization)
  }

  get organizationLimitsDomains () {
    return this.organizationLimits.domains
  }

  get organizations () {
    return this.organizationModule.organizations.filter(o => o.id !== this.organization.id)
  }

  get landscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.landscapeId)!
  }

  get landscapes () {
    return this.landscapeModule.landscapes
  }

  get otherLandscapes () {
    return this.landscapeModule.landscapes.filter(o => o.id !== this.landscapeId)
  }

  get landscapePermission () {
    return this.landscapeModule.landscapePermission(this.landscape)
  }

  get landscapeLimitReached () {
    return this.landscapes.length >= this.organizationLimits.landscapes
  }

  close () {
    this.menuRef.isActive = false
  }

  async duplicateLandscape (landscapeId: string) {
    const landscape = this.landscapeModule.landscapes.find(o => o.id === landscapeId)
    if (!landscape) {
      throw new Error('Could not find landscape')
    }

    this.close()

    this.$replaceQuery({
      landscapes_appbar_menu: undefined
    })

    const newLandscape = await this.landscapeModule.landscapeDuplicate({
      landscapeId: landscape.id,
      name: `${landscape.name} duplicate`,
      organizationId: this.organization.id
    })

    analytics.landscapeDuplicate.track(this, {
      landscapeId: [landscape.id, newLandscape.id],
      landscapeName: newLandscape.name,
      landscapeOrganizationChanged: false,
      organizationId: [landscape.organizationId, newLandscape.organizationId]
    })
    analytics.landscapeCreate.track(this, {
      landscapeId: [newLandscape.id],
      landscapeName: newLandscape.name,
      organizationId: [newLandscape.organizationId]
    })

    this.alertModule.pushAlert({
      color: 'success',
      message: `Duplicated ${landscape.name}`
    })

    this.$router.push({
      name: 'landscape',
      params: {
        landscapeId: newLandscape.id,
        organizationId: newLandscape.organizationId
      }
    })
  }
}
