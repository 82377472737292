
import { FormController, validationRules } from '@icepanel/app-form'
import { Landscape } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { OrganizationModule } from '@/modules/organization/store'

import * as analytics from '../helpers/analytics'
import { LandscapeModule } from '../store'

@Component({
  components: {
    Dialog
  },
  name: 'LandscapeDuplicateDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  landscape: Landscape | null = null

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get landscapeDuplicateDialog () {
    return this.$queryValue('landscape_duplicate_dialog')
  }

  get landscapeId () {
    return this.landscapeDuplicateDialog?.split('-')[0] || null
  }

  get landscapeOrganizationId () {
    return this.landscapeDuplicateDialog?.split('-')[1] || null
  }

  get organizations () {
    return this.organizationModule.organizations.filter(o => o.id !== this.currentOrganization?.id).sort((a, b) => a.name.localeCompare(b.name))
  }

  get targetOrganization () {
    return this.organizations.find(o => o.id === this.formController.model.targetOrganizationId)
  }

  get items () {
    return this.organizationModule.organizations
      .filter(o => o.id !== this.currentOrganization?.id)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(o => ({
        id: o.id,
        text: o.name,
        value: o.id
      }))
  }

  formController = new FormController({
    initialModel: {
      targetOrganizationId: '' as string
    },
    validationRules: {
      targetOrganizationId: validationRules.exists
    }
  })

  async loadLandscape () {
    if (this.landscapeId && this.landscapeOrganizationId) {
      this.landscape = await this.landscapeModule.landscapeFind({
        landscapeId: this.landscapeId,
        organizationId: this.landscapeOrganizationId
      })
    } else {
      this.$emit('close')
    }
  }

  created () {
    this.formController.submitHandler = async () => {
      const landscape = this.landscape
      if (!landscape) {
        throw new Error('Could not find landscape')
      }

      const organization = this.currentOrganization
      if (!organization) {
        throw new Error('Could not find organization')
      }

      const targetOrganization = this.targetOrganization
      if (!targetOrganization) {
        throw new Error('Could not find target organization')
      }

      const newLandscape = await this.landscapeModule.landscapeDuplicate({
        landscapeId: landscape.id,
        name: `${landscape.name} duplicate`,
        organizationId: organization.id,
        targetOrganizationId: targetOrganization.id
      })

      analytics.landscapeDuplicate.track(this, {
        landscapeId: [landscape.id, newLandscape.id],
        landscapeName: newLandscape.name,
        landscapeOrganizationChanged: true,
        organizationId: [landscape.organizationId, newLandscape.organizationId]
      })
      analytics.landscapeCreate.track(this, {
        landscapeId: [newLandscape.id],
        landscapeName: newLandscape.name,
        organizationId: [newLandscape.organizationId]
      })

      this.alertModule.pushAlert({
        color: 'success',
        message: `Duplicated ${landscape.name}`
      })

      this.$router.push({
        name: 'landscape',
        params: {
          landscapeId: newLandscape.id,
          organizationId: newLandscape.organizationId
        }
      })
    }
  }

  async open () {
    this.formController.resetModel()
    this.formController.resetStatus()

    await this.loadLandscape()
  }

  opened () {
    analytics.landscapeDuplicateDialog.track(this, {
      landscapeId: this.landscapeId ? [this.landscapeId] : [],
      organizationId: [this.currentOrganizationId],
      originOrganizationId: this.landscapeOrganizationId ? [this.landscapeOrganizationId] : undefined
    })
  }
}
