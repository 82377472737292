
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import OrganizationUpgradeMenu from '@/modules/organization/components/upgrade-menu.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { UserModule } from '@/modules/user/store'

import { LandscapeModule } from '../store'
import ActionsMenu from './actions-menu.vue'
import NameEdit from './name-edit.vue'

@Component({
  components: {
    ActionsMenu,
    NameEdit,
    OrganizationUpgradeMenu
  },
  name: 'LandscapeList'
})
export default class extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  @Ref() readonly listRef!: { $el: HTMLElement }
  @Ref() readonly listItemRefs!: { $el: HTMLElement }[]

  @Prop() readonly activator?: () => HTMLElement

  editingLandscapeId: string | null = null

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || 'latest'
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get organizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }

  get organizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get landscapes () {
    return this.landscapeModule.landscapes
  }

  get landscapeLimitReached () {
    return this.landscapes.length >= this.organizationLimits.landscapes
  }

  async scrollToItem () {
    await new Promise(resolve => setTimeout(resolve, 100))

    const landscapeIndex = this.landscapes.findIndex(o => o.id === this.currentLandscapeId)
    const listItem = this.listItemRefs[landscapeIndex]
    if (listItem) {
      const listHeight = this.listRef.$el.clientHeight
      this.listRef.$el.scrollTop = listItem.$el.offsetTop - (listHeight / 2)
    }
  }

  newLandscapeAction () {
    if (!this.landscapeLimitReached) {
      this.$router.push({
        name: 'landscape-setup',
        query: {
          organization: this.currentOrganization?.id
        }
      })
    }
  }

  resetEditing () {
    this.editingLandscapeId = null
  }
}
