
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'

import { OrganizationModule } from '../store'

@Component({
  name: 'OrganizationOverdueAlert'
})
export default class OrganizationOverdueAlert extends Vue {
  landscapeModule = getModule(LandscapeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentOrganizationId () {
    return this.$params.organizationId || this.currentLandscape?.organizationId
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)
  }

  get organizationPermission () {
    return this.organizationModule.organizationPermission(this.currentOrganization)
  }
}
