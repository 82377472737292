
import { FormController, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'

import * as analytics from '../helpers/analytics'
import { LandscapeModule } from '../store'

@Component({
  name: 'LandscapeNameEdit'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)

  @Prop() readonly landscapeId!: string

  get landscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.landscapeId)!
  }

  formController = new FormController({
    initialModel: {
      name: ''
    },
    validationRules: {
      name: [
        ...validationRules.exists,
        ...validationRules.maxLength(128)
      ]
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      if (model.name === this.landscape.name) {
        this.$emit('success')
        return
      }

      await this.landscapeModule.landscapeUpdate({
        landscapeId: this.landscape.id,
        organizationId: this.landscape.organizationId,
        update: {
          name: model.name
        }
      })

      analytics.landscapeUpdate.track(this, {
        landscapeId: [this.landscape.id],
        landscapeName: model.name,
        organizationId: [this.landscape.organizationId]
      })
    }
    this.formController.on('success', () => {
      this.$emit('success')
    })
    this.formController.on('error', error => {
      this.$emit('cancel')

      this.alertModule.pushAlert({
        color: 'error',
        message: error
      })
    })

    this.formController.setInitialModel({
      name: this.landscape.name || ''
    })
    this.formController.resetModel()
  }
}
