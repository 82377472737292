
import { FormController, validationRules } from '@icepanel/app-form'
import { StructurizrWorkspace } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { OrganizationModule } from '@/modules/organization/store'
import { UserModule } from '@/modules/user/store'

import * as analytics from '../helpers/analytics'
import { LandscapeModule } from '../store'

@Component({
  components: {
    Dialog
  },
  name: 'LandscapeStructurizrImportDialog'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  get currentOrganizationId () {
    return (this.$params.organizationId || this.currentLandscape?.organizationId)!
  }

  get currentLandscapeId () {
    return this.$params.landscapeId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get valid () {
    return !!this.formController.model.structurizrWorkspace
  }

  formController = new FormController({
    initialModel: {
      structurizrWorkspace: null as File | null
    },
    validationRules: {
      structurizrWorkspace: validationRules.exists
    }
  })

  created () {
    this.formController.submitHandler = async model => {
      const text = await model.structurizrWorkspace?.text()
      if (!text) {
        throw new Error('Could not read file upload')
      }

      const structurizrWorkspace = JSON.parse(text) as StructurizrWorkspace

      const {
        landscape
      } = await this.landscapeModule.landscapeCreate({
        name: structurizrWorkspace.name || 'Structurizr import',
        organizationId: this.currentOrganizationId,
        structurizrWorkspace
      })

      analytics.landscapeStructurizrImport.track(this, {
        landscapeId: [landscape.id],
        organizationId: [landscape.organizationId]
      })
      analytics.landscapeCreate.track(this, {
        landscapeId: [landscape.id],
        landscapeName: landscape.name,
        organizationId: [landscape.organizationId]
      })

      this.$router.push({
        name: 'editor-diagram',
        params: {
          landscapeId: landscape.id
        }
      })
    }
  }

  opened () {
    this.formController.resetModel()
    this.formController.resetStatus()

    analytics.landscapeStructurizrImportDialog.track(this, {
      organizationId: [this.currentOrganizationId]
    })
  }
}
